<template>
  <v-data-table
    :headers="columns"
    :items="tableData"
    :items-per-page="10"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    :options.sync="options"
    class="elevation-1"
  >
    <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
      <div style="display: inline-block; padding: 16px 0;" v-bind:key="i">
        <span>{{ header.text }}</span>
      </div>
      <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
        <v-menu
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
          left
          fixed
          style="position: absolute; right: 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" icon v-bind="attrs" v-on="on">
              <v-icon
                small
                :color="
                  activeFilters[header.value] &&
                  activeFilters[header.value].length <
                    filters[header.value].length
                    ? 'red'
                    : 'default'
                "
              >
                mdi-filter-variant
              </v-icon>
            </v-btn>
          </template>
          <v-list flat dense class="pa-0">
            <v-list-item-group
              multiple
              v-model="activeFilters[header.value]"
              class="py-2"
            >
              <div class="search_input">
                <v-text-field
                  hide-details
                  v-model="searchString[header.value]"
                  placeholder="Search ..."
                  class="pl-2 pr-2"
                  @input="searchItem(header.value)"
                ></v-text-field>
              </div>
              <template v-for="(item, j) in filters[header.value]">
                <v-list-item v-bind:key="`A${j}`" :value="item" :ripple="false">
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        :true-value="item"
                        @click="toggle"
                        color="primary"
                        :ripple="false"
                        dense
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn
                  text
                  block
                  @click="toggleAll(header.value)"
                  color="success"
                  >{{ $t("toggleall") }}</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  text
                  block
                  @click="clearAll(header.value)"
                  color="warning"
                  >{{ $t("clearall") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-autocomplete
        v-model="editedItem.status"
        :items="user_status"
        filled
        :hide-details="true"
        dense
        v-if="item.id === editedItem.id"
      ></v-autocomplete>
      <span v-else>{{ item.status }}</span>
    </template>
    <template v-slot:item.value="{ item }">
      <v-text-field
        class="mt-4"
        v-model="editedItem.value"
        type="number"
        dense
        single-line
        v-if="item.id === editedItem.id"
      ></v-text-field>
      <span v-else>{{ item.value }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <div v-if="item.id === editedItem.id">
        <v-icon color="red" class="mr-3" @click="close">
          mdi-window-close
        </v-icon>
        <v-icon color="green" @click="save">
          mdi-content-save
        </v-icon>
      </div>
      <div v-else>
        <v-icon color="green" class="mr-3" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "veTableObjectivePOSValues",
  props: ["row", "current_row", "updatedRowData"],
  data: function() {
    return {
      current_rows: null,
      tableData: [],
      tempdata: [],
      originaltableData: [],
      options: {},
      loading: false,
      filterCriteria: {},
      user_status: ["active", "inactive"],
      filters: { name: [], status: [], posId: [], value: [] },
      initFilterList: { name: [], status: [], posId: [], value: [] },
      activeFilters: {},
      searchString: { name: "", status: "", posId: "", value: "" },
      editedIndex: -1,
      editedItem: {
        id: 0,
        value: null,
        status: "active",
      },
      defaultItem: {
        id: 0,
        value: null,
        status: "active",
      },
    };
  },
  watch: {
    async row(newVal, oldVal) {
      if (newVal) {
        this.init(newVal);
      }
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true,
    },
  },
  computed: {
    columns() {
      return [
        {
          text: "IdPOS",
          value: "posId",
          width: "100px",
          filter: (value) => {
            return this.activeFilters.posId
              ? this.activeFilters.posId.includes(value)
              : true;
          },
        },
        {
          text: "POS " + this.$t("brands.name"),
          value: "name",
          width: "200px",
          filter: (value) => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          },
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          },
        },
        {
          text: this.$t("objective"),
          value: "value",
          width: "150px",
          filter: (value) => {
            return this.activeFilters.value
              ? this.activeFilters.value.includes(value)
              : true;
          },
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: 100,
        },
      ];
    },
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map((d) => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "posId" || col === "value") {
          this.filters[col] = this.initFilterList[col].filter(
            (item) => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter((item) =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map((d) => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      if (this.editedIndex > -1) {
        try {
          let postData = {
            id: this.editedItem.id,
            status: this.editedItem.status,
            value: this.editedItem.value,
          };
          let response = await ApiService.put(
            "objectives/save_edited_objective_pos/" + this.editedItem.id,
            postData
          );
          if (response) {
            await this.updatedRowData(this.current_row);
          }
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    init(newVal) {
      this.current_rows = newVal;
      this.tableData = this.current_rows;
      this.originaltableData = this.current_rows;
      this.tempdata = this.current_rows;
      this.initFilters();
    },
  },
  async updated() {},
  async mounted() {
    this.init(this.row);
  },
};
</script>

<style></style>
