<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showGoDialog"
      v-if="row !== null && row !== undefined"
      max-width="90%"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <div class="title_contents">
              <div class="top_title">
                <h4>{{ row.name }}</h4>
              </div>
              <div class="objective_content">
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{
                        this.$t("brands.brand") + " " + this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ row.brand.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title">{{ this.$t("Types") }}: </span>
                    <span>{{ objectiveTypesObj[row.types] }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title">{{ this.$t("weight") }}: </span>
                    <span>{{ row.weight }} %</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("salesforce.status") }}:
                    </span>
                    <span>{{ row.status }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("brands.start_date") }}:
                    </span>
                    <span>{{ row.start_date_label }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("brands.end_date") }}:
                    </span>
                    <span>{{ row.end_date_label }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{
                        this.$t("objective") + " " + this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ row.name }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    v-if="row.types === 'Actions'"
                  >
                    <span class="title">{{ this.$t("value") }}: </span>
                    <span
                      v-if="
                        actual_sum_value !== null && actual_sum_value !== ''
                      "
                      :style="
                        actual_sum_value !== row.value ? 'color: red;' : ''
                      "
                      >{{ actual_sum_value }} ( {{ row.value }} )</span
                    >
                    <span
                      v-if="
                        actual_sum_value === null || actual_sum_value === ''
                      "
                      >{{ row.value }}</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" md="5">
                    <span class="title"
                      >{{ this.$t("brands.description") }}:
                    </span>
                    <span>{{ row.description }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row
              class="mt-2"
              style="max-height: calc(100vh - 450px); overflow-y: auto;"
            >
              <v-col cols="12" sm="12" md="12">
                <v-row v-if="row.types === 'Actions'">
                  <v-col cols="12" sm="12" md="6">
                    <RouteObjectiveTable
                      :row="row"
                      :changeActualSumValue="changeActualSumValue"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <AccomplishmentScaleTable
                      :objectiveId="row.id"
                      :scales="saveData.scales"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="row.types === 'Sales'">
                  <v-col cols="12" sm="12" md="12">
                    <span class="obj-title"
                      >{{ this.$t("Sales Objective Type") }}:
                    </span>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="flex-center">
                        <v-radio-group
                          v-model="saveData.objective_salesType"
                          row
                        >
                          <template v-for="item in salesTypes">
                            <v-radio
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></v-radio>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="row.types === 'Sales' || row.types === 'Info'">
                  <v-col cols="12" sm="12" md="6">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        style="display: flex; justify-content: space-between; align-items: center;"
                      >
                        <h5>{{ this.$t("IdPOS with the objective") }}:</h5>
                        <div>
                          <v-btn
                            color="success"
                            dark
                            class="mr-2"
                            @click="onExportTemplate"
                          >
                            Plantilla Import
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            class="mr-2"
                            :loading="isUploadingExcelFile"
                            @click="onImportIDPOSwithObjective"
                            >Import</v-btn
                          >
                          <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            accept=".xls,.xlsx"
                            @change="onFileChanged"
                          />
                          <v-btn
                            color="success"
                            dark
                            class="mr-2"
                            @click="onExportIDPOSwithObjective"
                            >Export</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <ObjectivePosTable :row="this.current_row" />
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <AccomplishmentScaleTable
                          :objectiveId="row.id"
                          :scales="saveData.scales"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isProgressing"
              @click="onSaveClick(saveData)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import AccomplishmentScaleTable from "./AccomplishmentScaleTable.vue";
import RouteObjectiveTable from "./RouteObjectiveTable.vue";
import ObjectivePosTable from "./ObjectivePosTable.vue";
import * as XLSX from "xlsx";

export default {
  name: "goDialog",
  components: {
    AccomplishmentScaleTable,
    RouteObjectiveTable,
    ObjectivePosTable,
  },
  props: [
    "value",
    "row",
    "tableList",
    "statusType",
    "objectiveTypes",
    "objectiveTypesObj",
    "salesTypes",
    "reloadTable",
  ],
  data: function() {
    return {
      saveData: null,
      isProgressing: false,
      isUploadingExcelFile: false,
      selectedFile: null,
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      actual_sum_value: "",
      current_row: null,
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      this.actual_sum_value = "";
      console.log("this.current_row - ", this.current_row);
    },
  },
  computed: {
    showGoDialog: {
      get() {
        this.init();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async updatedRowData(row_data) {
      let fetch_url = "objectives/get_pos_objective_data/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.current_row = resp.data;
      } catch (error) {}
    },
    changeActualSumValue(changedValue) {
      this.actual_sum_value = changedValue;
    },
    async onSaveClick(saveData) {
      try {
        this.isProgressing = true;
        let status = await ApiService.post(`objectives/savegodata`, saveData);
        this.reloadTable();
        this.isProgressing = false;
        this.showGoDialog = false;
      } catch (error) {
        this.reloadTable();
        this.showGoDialog = false;
      }
    },
    init() {
      let model = {
        scales: _.get(this.row, "objective_accomplishment_scales"),
        objective_salesType: _.get(this.row, "salesTypes"),
        objectiveId: _.get(this.row, "id"),
      };
      if (model.scales === undefined) {
        model.scales = [];
      }
      this.saveData = model;
    },
    async onExportIDPOSwithObjective() {
      let objectiveId = _.get(this.row, "id");
      let fetch_url =
        "objectives/downloadexcelIDPOSwithObjective/" + objectiveId;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "objectives_pos.xlsx");
    },
    async onImportIDPOSwithObjective() {
      this.isUploadingExcelFile = true;
      window.addEventListener(
        "focus",
        () => {
          this.isUploadingExcelFile = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      let objectiveId = _.get(this.row, "id");
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isUploadingExcelFile = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        const response = await ApiService.post(
          `objectives/uploadexcelIDPOSwithObjective/` + objectiveId,
          data,
          {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          }
        );
        const {
          success,
          insertedRowCount,
          failedRows,
          invalidFile,
          message,
        } = response;
        this.snackbar_class = "deep-purple";
        if (success && insertedRowCount > 0) {
          this.text_snackbar = `${insertedRowCount} POS${
            insertedRowCount > 1 ? " are " : " is "
          } imported successfully`;
          this.snackbar = true;
        } else if (failedRows) {
          this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
          this.snackbar = true;
          this.exportCSVfileGlobal(response.titles, response.failedRows);
        } else if (!invalidFile && message) {
          this.text_snackbar = message;
          this.snackbar_class = "red";
          this.snackbar = true;
        }
        this.isUploadingExcelFile = false;
        await this.reloadTable();
        await this.updatedRowData(this.current_row);
      }
    },
    async onExportTemplate() {
      if (this.current_row && this.current_row?.objective_pos) {
        let objectivePosArr = this.current_row.objective_pos.map((item) => {
          let push_item = { ...item };
          push_item.name = item.po.name;
          push_item.status = item.po.status;
          return push_item;
        });
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "import_templates";
        let headers = [
          { label: "ID POS", key: "posId" },
          // { label: "POS Name", key: "name" },
          // { label: "Value", key: "value" },
        ];
        if (this.row.types === "Sales") {
          headers.push({ label: "Value", key: "value" });
        }
        let keyMap = {};
        let emptyRow = {};
        headers.forEach((column) => {
          keyMap[column.key] = column.label;
          emptyRow[column.key] = "";
        });
        let content = [];
        objectivePosArr.map((items) => {
          let row = { ...emptyRow };
          for (const key of Object.keys(items)) {
            if (items[key] && keyMap[key]) {
              row[key] = items[key];
            }
          }
          content.push(row);
        });

        let Heading = [headers.map((el) => el.label)];
        //Had to create a new workbook and then add the header
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, content, {
          origin: "A2",
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, ws, "data");
        XLSX.writeFile(wb, fileName + fileExtension);
      }
    },
  },
  async updated() {},
  async mounted() {
    this.init();
  },
};
</script>

<style>
.objective_content .row span {
  color: #000;
  font-size: 1rem;
}
.objective_content .row span.title {
  font-weight: bold;
  font-size: 1rem !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
.title_contents {
  border: 3px solid #727272;
  border-radius: 5px;
}
.top_title h4 {
  margin: 0;
}
.top_title {
  padding: 15px 15px 15px 15px;
  border-bottom: 3px solid #727272;
}
.objective_content {
  padding: 15px;
}
</style>
