<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="display: flex; justify-content: space-between; align-items: center;"
        >
          <span class="obj-title"
            >{{ $t("Accomplishment Scale") }}:
          </span>
          <v-btn color="primary" class="ml-2 white--text" @click="addNew">
            <v-icon dark>mdi-plus</v-icon>Add
          </v-btn>
        </v-col>
      </v-row>
      <v-row :justify="'center'" class="mt-0">
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="headers"
            :items="scales"
            class="elevation-1"
            :hide-default-footer="true"
          >
            <template v-slot:item.fromValue="{ item }">
              <v-text-field
                v-model="editedItem.fromValue"
                :hide-details="true"
                type="number"
                dense
                single-line
                :autofocus="true"
                v-if="item.id === editedItem.id"
              ></v-text-field>
              <span v-else>{{ item.fromValue }}</span>
            </template>
            <template v-slot:item.toValue="{ item }">
              <v-text-field
                v-model="editedItem.toValue"
                :hide-details="true"
                type="number"
                dense
                single-line
                v-if="item.id === editedItem.id"
              ></v-text-field>
              <span v-else>{{ item.toValue }}</span>
            </template>
            <template v-slot:item.accomplishedValue="{ item }">
              <v-text-field
                v-model="editedItem.accomplishedValue"
                :hide-details="true"
                type="number"
                dense
                single-line
                v-if="item.id === editedItem.id"
              ></v-text-field>
              <span v-else>{{ item.accomplishedValue }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.id === editedItem.id">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="red" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:no-data>
              Empty
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccomplishmentScaleTable",
  props: ["scales"],
  data: function() {
    return {
      editedIndex: -1,
      editedItem: {
        id: 0,
        fromValue: 0,
        toValue: 0,
        accomplishedValue: 0,
      },
      defaultItem: {
        id: 0,
        fromValue: 0,
        toValue: 0,
        accomplishedValue: 0,
      },
      headers: [
        {
          text: "From",
          value: "fromValue",
          sortable: false,
          width: "25%",
        },
        {
          text: "To",
          value: "toValue",
          sortable: false,
          width: "25%",
        },
        {
          text: "%Accomplished",
          value: "accomplishedValue",
          sortable: false,
          width: "25%",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    addNew() {
      const addObj = Object.assign({}, this.defaultItem);
      if (this.scales.length > 0) {
        addObj.id = this.scales[this.scales.length - 1].id + 1;
      } else {
        addObj.id = this.scales.length + 1;
      }
      this.scales.push(addObj);
      this.editItem(addObj);
    },

    editItem(item) {
      this.editedIndex = this.scales.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      const index = this.scales.indexOf(item);
      this.scales.splice(index, 1);
    },

    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.scales[this.editedIndex], this.editedItem);
      }
      this.close();
    },
    init() {},
  },
  async updated() {
    this.init();
  },
  async mounted() {
    this.init();
  },
};
</script>

<style>
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
</style>
