<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ $t("navbar.objectives") }}</v-card-title>

      <goDialog
        v-model="showGoDialog"
        :row="selectedRow"
        :statusType="status_type"
        :objectiveTypes="objective_types"
        :objectiveTypesObj="objectiveTypes"
        :salesTypes="salesTypes"
        :reloadTable="getDataFromApi"
        :tableList="objectives"
      />

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.types"
                      v-bind:label="$t('Types')"
                      :items="objective_types"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brand"
                      :loading="isBrandSearching"
                      :items="filtered_brands"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeBrand"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="['Info', 'Actions'].includes(selected.types)"
                  >
                    <v-autocomplete
                      v-model="selected.surveyId"
                      v-bind:label="$t('survey')"
                      :items="selectableSurveys"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      @change="onChangeSurvey"
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="['Actions'].includes(selected.types)"
                  >
                    <v-autocomplete
                      v-model="selected.surveyQuestionId"
                      v-bind:label="$t('Question')"
                      :items="selectableQuestionList"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.isInclude"
                      v-bind:label="$t('Include')"
                      :items="isIncludesList"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.value"
                      v-bind:label="$t('value')"
                      type="number"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="start_date_menu"
                      v-model="select_start_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.start_date"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.start_date"
                        no-title
                        @input="select_start_date_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="end_date_menu"
                      v-model="select_end_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.end_date"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.end_date"
                        no-title
                        scrollable
                        @input="select_end_date_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(selected)"
                :loading="isSaving"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              @click="downloadExcelPOSObjectiveAll"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export POS Objective
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="assortment-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="objectives"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalobjectives"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import download from "js-file-download";
import goDialog from "./goDialog.vue";

export default {
  name: "Objectives",
  components: {
    goDialog,
  },
  data: function() {
    return {
      /********** selectable options **********/
      status_type: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      objective_types: [
        { label: "Info", value: "Info" },
        { label: "Sales", value: "Sales" },
        { label: "Actions", value: "Actions" },
      ],
      objectiveTypes: {},
      salesTypes: [
        { label: "Absolute", value: "ABSOLUTE" },
        { label: "Growth Last Year", value: "GROWTH_LAST_YEAR" },
        { label: "Growth Last Month", value: "GROWTH_LAST_MONTH" },
        { label: "Growth Last Visit", value: "GROWTH_LAST_VISIT" },
      ],
      isIncludesList: [],
      /********** form-validation options **********/
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      weightRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "This field should be above 0",
        (v) => (v && v <= 100) || "This field should not be above 100",
      ],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",

      /********** create or edit dialog options **********/
      isSaving: false,
      showEdit: false,
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
      select_start_date_menu: false,
      select_end_date_menu: false,
      /********** vue-easytable options **********/
      totalobjectives: 0,
      objectives: [],
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "name",
        "brand",
        "description",
        "types",
        "value",
        "survey",
        "survey_question",
        "isInclude",
        "status",
        "start_date",
        "end_date",
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      filterCriteria: {
        types: {},
        value: {},
        survey: {},
        survey_question: {},
        isInclude: {},
        status: {},
        start_date: {},
        end_date: {},
        name: {},
        description: {},
        brand: {},
      },
      showEmpty: false,

      /********** vue-easytable options **********/
      showGoDialog: false,
      selectedRow: null,

      surveyList: [],
      surveyQuestionList: [],
      selectableSurveys: [],
      selectableQuestionList: [],
    };
  },
  watch: {
    async search_brand(val) {
      await this.search_brand_list(val);
    },
  },
  computed: {
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC",
          fixed: "left",
        },
        {
          key: "brand",
          title: this.$t("brands.brand") + " " + this.$t("brands.name"),
          field: "brand",
          align: "left",
          width: 180,
          sortBy: "",
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`objectives/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "types",
          title: this.$t("Types"),
          field: "types",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.typesLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.objective_types.map((item) => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["types"][item.value]}
                        label={item.label}
                        value={item.value}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "types")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "name",
          title: this.$t("objective") + " " + this.$t("brands.name"),
          field: "name",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`objectives/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`objectives/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "value",
          title: this.$t("value"),
          field: "value",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["value"]["from"]}
                    value={this.filterCriteria["value"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["value"]["to"]}
                    value={this.filterCriteria["value"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "value")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "survey",
          title: this.$t("survey"),
          field: "survey",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.survey_label}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["survey"]}
                    apiUrl={`objectives/getfilterlist`}
                    columnName={`survey`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["survey"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "survey")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "survey_question",
          title: this.$t("Question"),
          field: "survey_question",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.survey_question_label}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["survey_question"]}
                    apiUrl={`objectives/getfilterlist`}
                    columnName={`survey_question`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["survey_question"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "survey_question")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "isInclude",
          title: this.$t("Include"),
          field: "isInclude",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.isIncludeLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  {this.isIncludesList.map((item) => {
                    return (
                      <v-checkbox
                        v-model={this.filterCriteria["isInclude"][item.value]}
                        label={item.label}
                        value={item.value}
                      ></v-checkbox>
                    );
                  })}
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "isInclude")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "status",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "start_date",
          title: this.$t("brands.start_date"),
          field: "start_date",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.start_date !== null
                  ? moment(row.start_date)
                      .local()
                      .format("DD/MM/YYYY")
                  : ""}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["start_date"]["from"]}
                    value={this.filterCriteria["start_date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["start_date"]["to"]}
                    value={this.filterCriteria["start_date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "start_date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "end_date",
          title: this.$t("brands.end_date"),
          field: "end_date",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.end_date !== null
                  ? moment(row.end_date)
                      .local()
                      .format("DD/MM/YYYY")
                  : ""}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["end_date"]["from"]}
                    value={this.filterCriteria["end_date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["end_date"]["to"]}
                    value={this.filterCriteria["end_date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "end_date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "actions",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          },
        },
        {
          key: "go",
          title: this.$t("go"),
          field: "go",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row.id)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          },
        },
        {
          key: "copy",
          title: this.$t("copy"),
          field: "copy",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  dark
                  color="success darken-1"
                  text
                  on-click={() => this.onItemCopy(row.id)}
                >
                  <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    async search_brand_list(val) {
      let search_str = "";
      if (!val) search_str = "";
      let fetch_url =
        "objectives/get_selectable_brands?filter_name=" + search_str;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    /********** vue-easytable **********/
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#assortment-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (["value", "start_date", "end_date"].includes(cancelFilterKey)) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        [
          "status",
          "types",
          "name",
          "brand",
          "survey",
          "survey_question",
          "isInclude",
          "description",
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey] = {};
      } else {
        this.filterCriteria[cancelFilterKey] = null;
      }
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "start_date" || key === "end_date") {
            let convert_value = {
              from: "",
              to: "",
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = moment(value["from"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value,
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = moment(value["to"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value,
              });
            }
          } else if (key === "weight" || key === "value") {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value,
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "objectives";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.salesTypes = resp.salesTypeList;
      this.status_type = resp.status_type;
      this.objective_types = resp.objective_type_list;
      this.objectiveTypes = resp.objective_types;
      this.isIncludesList = resp.isIncludesList;
      this.surveyList = resp.surveyList;
      this.selectableSurveys = this.surveyList;
      this.updateSurveyQuestionList(null);
      this.objectives = resp.data;
      if (this.objectives.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.totalobjectives = resp.total;
      this.close();
    },
    updateSurveyQuestionList(surveyId) {
      let survey_questions = [];
      if (this.surveyList.length > 0) {
        this.surveyList.map((surveyItem) => {
          if (
            (surveyId === null ||
              (surveyId !== null && surveyId === surveyItem.id)) &&
            surveyItem?.survey_components &&
            surveyItem.survey_components.length > 0
          ) {
            surveyItem.survey_components.map((componentItem) => {
              if (
                componentItem?.survey_questions &&
                componentItem.survey_questions.length > 0
              ) {
                survey_questions = [
                  ...survey_questions,
                  ...componentItem.survey_questions,
                ];
              }
            });
          }
        });
      }
      this.surveyQuestionList = survey_questions;
      this.selectableQuestionList = survey_questions;
    },
    onChangeBrand() {
      this.selectableSurveys = this.surveyList;
      if (this.selected.brandId) {
        this.selectableSurveys = this.surveyList.filter(
          (el) => el.brandId === this.selected.brandId
        );
        this.selectableQuestionList = [];
      }
    },
    onChangeSurvey() {
      console.log("this.selected.surveyId - ", this.selected.surveyId);
      this.updateSurveyQuestionList(this.selected.surveyId);
    },

    /********** create, update **********/
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async createSelectedModel(item) {
      let model_start_date = _.get(item, "start_date");
      let model_end_date = _.get(item, "end_date");
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        types: _.get(item, "types"),
        brandId: _.get(item, "brandId"),
        description: _.get(item, "description"),
        value: _.get(item, "value"),
        status: _.get(item, "status"),
        surveyId: _.get(item, "surveyId"),
        surveyQuestionId: _.get(item, "surveyQuestionId"),
        isInclude: _.get(item, "isInclude"),
        start_date:
          model_start_date !== undefined && model_start_date !== null
            ? moment(model_start_date).format("YYYY-MM-DD")
            : model_start_date,
        end_date:
          model_end_date !== undefined && model_end_date !== null
            ? moment(model_end_date).format("YYYY-MM-DD")
            : model_end_date,
      };
      await this.search_brand_list("");
      if (model.surveyId) {
        this.updateSurveyQuestionList(model.surveyId);
      }
      return model;
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let start_date_time = new Date(item.start_date);
        let end_date_time = new Date(item.end_date);
        if (start_date_time - end_date_time > 0) {
          logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
          return;
        }
        let body = _.omit(item, ["_extended"]);
        let status = null;
        try {
          this.isSaving = true;
          let startdate = moment(body.start_date, "YYYY-MM-DD");
          let enddate = moment(body.end_date, "YYYY-MM-DD");
          body.start_date = startdate.utc().format("YYYY-MM-DD HH:mm:ssZ");
          body.end_date = enddate.utc().format("YYYY-MM-DD HH:mm:ssZ");
          if (body.id) {
            status = await ApiService.put(`objectives/${body.id}`, body);
          } else {
            status = await ApiService.post(`objectives`, body);
          }
          if (status !== null) {
            const { success, message, data } = status;
            if (success && data) {
              logInfo("Objetivos actualizada");
              this.showEdit = false;
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
          this.isSaving = false;
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async onItemCopy(id) {
      if (window.confirm(`Are you sure you want to copy?`)) {
        try {
          let status = await ApiService.post(`objectives/copydata/${id}`);
          if (status !== null) {
            const { success, message, data } = status;
            if (success === undefined && data) {
              logInfo("Objetivos actualizada");
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
        } catch (error) {}
      }
    },
    async onItemGo(rowId) {
      await this.getDataFromApi();
      this.selectedRow = this.objectives.find((el) => el.id === rowId);
      this.selectedRow.start_date_label =
        this.selectedRow.start_date !== null
          ? moment(this.selectedRow.start_date)
              .local()
              .format("DD/MM/YYYY")
          : "";
      this.selectedRow.end_date_label =
        this.selectedRow.end_date !== null
          ? moment(this.selectedRow.end_date)
              .local()
              .format("DD/MM/YYYY")
          : "";
      // this.selectedRow = row;
      console.log("selectedRow - ", this.selectedRow);
      this.showGoDialog = true;
    },

    /********** export excel **********/
    async downloadExcel() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "objectives/downloadexcel";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        download(response, "objectives.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcelPOSObjectiveAll() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "objectives/downloadExcelPOSObjectiveAll";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        download(response, "objectives.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },

    init() {
      this.getDataFromApi();
    },

    /********** Go Dialog **********/
    setShowGoDialog(is_show_go_dialog) {
      this.showGoDialog = is_show_go_dialog;
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
