<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="display: flex; justify-content: space-between; align-items: center;"
        >
          <h5>
            {{ this.$t("Objective Total Routes") }}: {{ total_brand_pos_count }}
          </h5>
          <div>
            <v-btn color="success" dark class="mr-2" @click="onExportTemplate">
              Plantilla Import
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-2"
              :loading="isUploadingExcelFile"
              @click="onImportObjectiveRoutes"
              >Import</v-btn
            >
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="success"
              dark
              class="mr-2"
              @click="onExportObjectiveRoutes"
              >Export</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row :justify="'center'" class="mt-0">
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="columns"
            :items="tableData"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [10, 50, 100],
            }"
            :options.sync="options"
            class="elevation-1"
          >
            <template
              v-for="(col, i) in filters"
              v-slot:[`header.${i}`]="{ header }"
            >
              <div
                style="display: inline-block; padding: 16px 0;"
                v-bind:key="i"
              >
                <span>{{ header.text }}</span>
              </div>
              <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                  transition="slide-y-transition"
                  left
                  fixed
                  style="position: absolute; right: 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                      <v-icon
                        small
                        :color="
                          activeFilters[header.value] &&
                          activeFilters[header.value].length <
                            filters[header.value].length
                            ? 'red'
                            : 'default'
                        "
                      >
                        mdi-filter-variant
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list flat dense class="pa-0">
                    <v-list-item-group
                      multiple
                      v-model="activeFilters[header.value]"
                      class="py-2"
                    >
                      <div class="search_input">
                        <v-text-field
                          hide-details
                          v-model="searchString[header.value]"
                          placeholder="Search ..."
                          class="pl-2 pr-2"
                          @input="searchItem(header.value)"
                        ></v-text-field>
                      </div>
                      <template v-for="(item, j) in filters[header.value]">
                        <v-list-item
                          v-bind:key="`A${j}`"
                          :value="item"
                          :ripple="false"
                        >
                          <template v-slot:default="{ active, toggle }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                :true-value="item"
                                @click="toggle"
                                color="primary"
                                :ripple="false"
                                dense
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="header.value === 'route'"
                                v-text="item.name"
                              ></v-list-item-title>
                              <v-list-item-title
                                v-else
                                v-text="item"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <v-btn
                          text
                          block
                          @click="toggleAll(header.value)"
                          color="success"
                          >{{ $t("toggleall") }}</v-btn
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          text
                          block
                          @click="clearAll(header.value)"
                          color="warning"
                          >{{ $t("clearall") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-slot:item.spv="{ item }">
              <template v-for="spv_item in item.spv">
                <span :key="spv_item.id">{{ spv_item.name }}</span>
              </template>
            </template>
            <template v-slot:item.gpvs="{ item }">
              <template v-for="gpv_item in item.gpvs">
                <span :key="gpv_item.name + ' ' + item.routeId">{{
                  gpv_item.name
                }}</span
                ><br :key="gpv_item.name + ' ' + item.routeId + 'br'" />
              </template>
            </template>
            <template v-slot:item.route="{ item }">
              <span>{{ item.route_name }}</span>
            </template>
            <template v-slot:item.routeId="{ item }">
              <span>{{ item.routeId }}</span>
            </template>
            <template v-slot:item.posCount="{ item }">
              <span>{{ item.posCount }}</span>
            </template>
            <template v-slot:item.value="{ item }">
              <v-text-field
                v-model="editedItem.value"
                :hide-details="true"
                type="number"
                dense
                single-line
                :autofocus="true"
                v-if="item.id === editedItem.id"
              ></v-text-field>
              <span v-else>{{ item.value }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.id === editedItem.id">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
            <template v-slot:no-data>
              Empty
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import download from "js-file-download";
import * as XLSX from "xlsx";

export default {
  name: "RouteObjectiveTable",
  props: ["row", "objectiveId", "changeActualSumValue"],
  data: function() {
    return {
      rowData: [],
      total_brand_pos_count: 0,
      isUploadingExcelFile: false,

      tableData: [],
      tempdata: [],
      originaltableData: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      editedIndex: -1,
      editedItem: {
        id: 0,
        value: 0,
      },
      defaultItem: {
        id: 0,
        value: 0,
      },
      options: {},
      filterCriteria: {},

      filters: {
        spv_name: [],
        routeId: [],
        route_name: [],
        gpv_name: [],
        posCount: [],
        value: [],
      },
      initFilterList: {
        spv_name: [],
        routeId: [],
        route_name: [],
        gpv_name: [],
        posCount: [],
        value: [],
      },
      searchString: {
        spv_name: "",
        routeId: "",
        route_name: "",
        gpv_name: "",
        posCount: "",
        value: "",
      },
      activeFilters: {},
    };
  },
  watch: {
    async row(newVal, oldVal) {
      await this.updatedRowData(newVal);
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true,
    },
  },
  computed: {
    columns() {
      return [
        {
          text: "SPV",
          value: "spv_name",
          align: "center",
          width: 120,
          filter: (value) => {
            return this.activeFilters.spv_name
              ? this.activeFilters.spv_name.includes(value)
              : true;
          },
        },
        {
          text: "ID Route",
          value: "routeId",
          align: "center",
          width: 150,
          filter: (value) => {
            return this.activeFilters.routeId
              ? this.activeFilters.routeId.includes(value)
              : true;
          },
        },
        {
          text: "Route Name",
          value: "route_name",
          align: "center",
          width: 200,
          filter: (value) => {
            return this.activeFilters.route_name
              ? this.activeFilters.route_name.includes(value)
              : true;
          },
        },
        {
          text: "Main GPV",
          value: "gpv_name",
          sortable: false,
          align: "center",
          width: 150,
          filter: (value) => {
            return this.activeFilters.gpv_name
              ? this.activeFilters.gpv_name.includes(value)
              : true;
          },
          // filter: (value) => {
          //   if (
          //     this.activeFilters?.gpvs &&
          //     this.activeFilters.gpvs.length > 0 &&
          //     value
          //   ) {
          //     let filtered_array = value.filter((gpv_item) =>
          //       this.activeFilters.gpvs.includes(gpv_item.username)
          //     );
          //     return filtered_array.length > 0;
          //   }
          //   return false;
          // },
        },
        {
          text: "Brand POS",
          value: "posCount",
          align: "center",
          width: 180,
          filter: (value) => {
            return this.activeFilters.posCount
              ? this.activeFilters.posCount.includes(value)
              : true;
          },
        },
        {
          text: "Objective",
          value: "value",
          align: "center",
          width: 180,
          filter: (value) => {
            return this.activeFilters.value
              ? this.activeFilters.value.includes(value)
              : true;
          },
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: 100,
        },
      ];
    },
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map((d) => d[key])
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        // if (key !== "gpvs") {
        //   this.searchString[key] = "";
        //   this.initFilterList[key] = this.tableData
        //     .map((d) => d[key])
        //     .filter((value, index, self) => {
        //       return self.indexOf(value) === index;
        //     });
        // }
        // else {
        //   let gpvs_data = this.tableData.map((d) => d[key]);
        //   console.log("gpvs_data - ", gpvs_data);
        //   let gpv_list = [];
        //   gpvs_data.map((gpvs) => {
        //     if (gpvs && gpvs.length > 0) {
        //       gpvs.map((gpv) => {
        //         console.log("3s2af1 - ", gpv);
        //         if (!gpv_list.includes(gpv.name)) {
        //           gpv_list.push(gpv.name);
        //         }
        //       });
        //     } else {
        //       gpv_list.push("");
        //     }
        //   });
        //   this.initFilterList[key] = gpv_list;
        // }
      }
      console.log("this.initFilterList - ", this.initFilterList);
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "routeId" || col === "posCount" || col === "value") {
          this.filters[col] = this.initFilterList[col].filter(
            (item) => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter((item) =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map((d) => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      console.log(this.tempdata);
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        console.log(sortBy);
        if (sortBy === "route") {
          sortBy = "route_name";
        } else if (sortBy === "spv") {
          sortBy = "spv_name";
        }
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    async onExportObjectiveRoutes() {
      // let objectiveId = this.row.id;
      // let fetch_url = "objectives/downloadexcelObjectiveRoutes/" + objectiveId;
      // const response = await ApiService.get(fetch_url, {
      //   responseType: "blob",
      // });
      // download(response, "objectives_routes.xlsx");
      
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "export";
      let headers = [
        { label: "SPV", key: "spv_name" },
        { label: "ID Route", key: "routeId" },
        { label: "Route Name", key: "route_name" },
        { label: "Main GPV", key: "gpv_name" },
        { label: "Brand POS", key: "posCount" },
        { label: "Value", key: "value" },
      ];
      let keyMap = {};
      let emptyRow = {};
      headers.forEach((column) => {
        keyMap[column.key] = column.label;
        emptyRow[column.key] = "";
      });
      let content = [];
      this.tableData.map((items) => {
        let row = { ...emptyRow };
        for (const key of Object.keys(items)) {
          if (items[key] && keyMap[key]) {
            row[key] = items[key];
          }
        }
        content.push(row);
      });

      let Heading = [headers.map((el) => el.label)];

      //Had to create a new workbook and then add the header
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);
      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(ws, content, {
        origin: "A2",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, "data");
      XLSX.writeFile(wb, fileName + fileExtension);
    },
    async onExportTemplate() {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "import_templates";
      let headers = [
        { label: "ID Route", key: "routeId" },
        // { label: "Route Name", key: "route_name" },
        { label: "Value", key: "value" },
      ];
      let keyMap = {};
      let emptyRow = {};
      headers.forEach((column) => {
        keyMap[column.key] = column.label;
        emptyRow[column.key] = "";
      });
      let content = [];
      this.tableData.map((items) => {
        let row = { ...emptyRow };
        for (const key of Object.keys(items)) {
          if (items[key] && keyMap[key]) {
            row[key] = items[key];
          }
        }
        content.push(row);
      });

      let Heading = [headers.map((el) => el.label)];

      //Had to create a new workbook and then add the header
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);
      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(ws, content, {
        origin: "A2",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, "data");
      XLSX.writeFile(wb, fileName + fileExtension);
    },
    async onImportObjectiveRoutes() {
      this.isUploadingExcelFile = true;
      window.addEventListener(
        "focus",
        () => {
          this.isUploadingExcelFile = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      let objectiveId = this.row.id;
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isUploadingExcelFile = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        const response = await ApiService.post(
          `objectives/uploadexcelObjectiveRoutes/` + objectiveId,
          data,
          {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          }
        );
        const {
          success,
          insertedRowCount,
          failedRows,
          invalidFile,
          message,
        } = response;
        this.snackbar_class = "deep-purple";
        if (success && insertedRowCount > 0) {
          this.text_snackbar = `${insertedRowCount} row${
            insertedRowCount > 1 ? "s" : ""
          } imported successfully`;
          this.snackbar = true;
        } else if (failedRows) {
          this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
          this.snackbar = true;
          this.exportCSVfileGlobal(response.titles, response.failedRows);
        } else if (!invalidFile && message) {
          this.text_snackbar = message;
          this.snackbar_class = "red";
          this.snackbar = true;
        }
        this.isUploadingExcelFile = false;
        this.init();
      }
    },
    async updatedRowData(row_data) {
      let fetch_url = "objectives/get_route_objective_data/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.rowData = resp.data;
        this.tableData = resp.data;
        this.originaltableData = resp.data;
        this.tempdata = resp.data;
        console.log("this.tableData - ", this.tableData);
        let actual_sum_value = 0;
        if (this.originaltableData.length > 0) {
          this.originaltableData.map((item) => {
            actual_sum_value += item.value;
            return item;
          });
          this.changeActualSumValue(actual_sum_value);
        }
        this.total_brand_pos_count = resp.total_brand_pos_count;
        this.initFilters();
      } catch (error) {}
    },
    editItem(item) {
      this.editedIndex = this.rowData.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      if (this.editedIndex > -1) {
        try {
          let postdata = {
            value: this.editedItem.value,
            total_brand_pos_count: this.total_brand_pos_count,
            posCount: this.editedItem.posCount,
            objectiveId: this.row.id,
          };
          let response = await ApiService.put(
            "objectives/save_route_objective_value/" + this.editedItem.id,
            postdata
          );
          if (response) {
            Object.assign(this.rowData[this.editedIndex], this.editedItem);
            this.row.value = response.objdata.value;
            await this.updatedRowData(this.row);
          }
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    init() {
      this.updatedRowData(this.row);
    },
  },
  //   async updated() {
  //     this.init();
  //   },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
