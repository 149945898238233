<template>
  <v-row class="mt-0">
    <v-col cols="12" sm="12" md="12">
      <veTableObjectivePOS
        v-if="this.row.types === 'Info'"
        :row="this.objectivePosArr"
        :current_row="this.current_row"
        :updatedRowData="updatedRowData"
      />
      <veTableObjectivePOSValues
        v-if="this.row.types === 'Sales'"
        :row="this.objectivePosArr"
        :current_row="this.current_row"
        :updatedRowData="updatedRowData"
      />
    </v-col>
  </v-row>
</template>

<script>
import veTableObjectivePOS from "@/components/ve-table-objective-pos";
import veTableObjectivePOSValues from "@/components/ve-table-objective-pos-values";
import ApiService from "@/services/apiService";

export default {
  name: "ObjectivePosTable",
  props: ["row"],
  components: {
    veTableObjectivePOS,
    veTableObjectivePOSValues,
  },
  data: function() {
    return {
      current_row: null,
      objectivePosArr: [],
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      if (newVal) {
        this.init();
      }
    },
  },
  computed: {},
  methods: {
    async updatedRowData(row_data) {
      let fetch_url = "objectives/get_pos_objective_data/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.current_row = resp.data;
        this.init();
      } catch (error) {}
    },
    init() {
      this.objectivePosArr = this.current_row.objective_pos;
      this.objectivePosArr = this.objectivePosArr.map((item) => {
        let push_item = { ...item };
        push_item.name = item.po.name;
        return push_item;
      });
    },
  },
  // async updated() {
  //   this.init();
  // },
  async mounted() {
    this.current_row = this.row;
    this.init();
  },
};
</script>

<style></style>
